import {useCallback, useState, type ReactElement} from 'react';
import {useTranslation} from 'react-i18next';

import type LeuteModel from '@refinio/one.models/lib/models/Leute/LeuteModel.js';
import type TopicModel from '@refinio/one.models/lib/models/Chat/TopicModel.js';
import {storeUnversionedObject} from '@refinio/one.core/lib/storage-unversioned-objects.js';
import type {ChatRequest, Topic} from '@refinio/one.models/lib/recipes/ChatRecipes.js';

import {useEditMode} from '@/hooks/appBar/common.js';
import {useNavigateBack} from '@/hooks/navigation.js';
import {CHAT_REQUEST_RESEARCHER_DATA} from '@/root/chat/attachmentViews/types.js';
import SelectableTopicList from '@/components/shareJournalEntryPopup/SelectableTopicList';

export default function ResearcherDataRequestView(props: {
    topicModel: TopicModel;
    leuteModel: LeuteModel;
}): ReactElement {
    const [shareWithTopics, setShareWithTopics] = useState<Topic[]>([]);
    const i18n = useTranslation();
    const goBack = useNavigateBack();
    const onDone = useCallback(async () => {
        const dataRequest: ChatRequest = {
            $type$: 'ChatRequest',
            for: CHAT_REQUEST_RESEARCHER_DATA
        };
        const dataRequestObject = await storeUnversionedObject(dataRequest);

        for (const topic of shareWithTopics) {
            const topicRoom = await props.topicModel.enterTopicRoom(topic.id);
            await topicRoom.sendMessageWithAttachmentAsHash(
                '',
                [dataRequestObject.hash],
                undefined,
                null
            );
        }
        goBack();
    }, [goBack, shareWithTopics]);

    useEditMode(goBack, onDone, i18n.t('edda_menu.researcherDataRequest'));

    return (
        <SelectableTopicList
            leuteModel={props.leuteModel}
            topicModel={props.topicModel}
            onChange={setShareWithTopics}
            disableGroups={true}
        />
    );
}
